.corFemur canvas {
  height: 100% !important;
  width: 100% !important;
}

.corFemur {
  margin-left: auto;
  margin-right: auto;
  margin-top: ($spacer * 1.5);
  margin-bottom: ($spacer * 3);
}

.corTibia canvas {
  height: 100% !important;
  width: 100% !important;
}

.corTibia {
  margin-left: auto;
  margin-right: auto;
  margin-top: ($spacer * 1.5);
  margin-bottom: ($spacer * 3);
}
.axiFemur canvas {
  height: 100% !important;
  width: 100% !important;
}

.axiFemur {
  margin-left: auto;
  margin-right: auto;
  margin-top: ($spacer * 1.5);
  margin-bottom: ($spacer * 3);
}

.axiTibia canvas {
  height: 100% !important;
  width: 100% !important;
}

.axiTibia {
  margin-left: auto;
  margin-right: auto;
  margin-top: ($spacer * 1.5);
  margin-bottom: ($spacer * 3);
}

.sagFemur canvas {
  height: 100% !important;
  width: 100% !important;
}

.sagFemur {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: ($spacer * 3);
}

.sagTibia canvas {
  height: 100% !important;
  width: 100% !important;
}

.sagTibia {
  margin-left: auto;
  margin-right: auto;
  margin-top: ($spacer * 1.5);
  margin-bottom: ($spacer * 3);
}


/*mx-auto mt-4 mb-5*/
