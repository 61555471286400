html, #root{
  height: 100%;
}
body{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.area{
  margin: 20px 80px;
  padding: 20px;
  border: solid black;
  font-size: 15px;
}
.row-margin-bottom {
  margin-bottom: 10px;
}
.table-border{
  width: 100%;
  border: solid black;
  margin-bottom: 20px;
}

.button-info{
  margin-bottom: 5px;
  width: 100%;
  font-size: 25px;
}
.button-size{
  width: 100%;
  height: 100%;
}
.surgeon-form{
  margin: 10px 20px;
  font-size: 16px;
}
.case-form,.shipping-form{
  margin: 20px 50px;
  font-size: 15px;
}
.detail-info{
  display: flex;
}

.margin{
  margin: 20% 0;
  height: 60%;
}
.admin_main_layout{
  margin: 80px;
  padding: 30px;
  border: solid black;
  font-size: 15px;
  &_status,&_button{
    padding: 40px;
  }
}
.case_list_layout{
  margin: 20px 80px;
  padding: 30px 100px;
  min-width: 1200px;
  font-size: 20px;
}
.surgeon_main_layout{
  margin: 200px;
  padding: 50px;
  border: solid black;
  font-size: 20px;
}
.surgeon_detail_layout{
  margin: 20px 40px;
  font-size: 16px;
}
.case_detail_layout{
  margin: 50px 40px;
  font-size: 16px;
}
.row_height{
  height: 100%;
}
.form-control{
  height: 100%;
  width: 100%;
}
.form-select{
  height: 30px;
  width: 100%;
}
.next-button{
  width: 100%;
  height: 100%;
}
.drop-test{
  width: 100%;
  font-size: 16px;
}
.approval{
  height: 850px;
}
.col-md,.col-lg{
  &-1,&-2,&-3,&-4,&-5,&-6,&-7,&-8,&-9,&-10,&-11,&-12{
    height: 100%;
  }
}
.row,.p5Canvas{
  height: 100%;
}
.date_filter,.react-datepicker-wrapper{
  width: 100%;

  margin: 0;
}
.react-datepicker__input-container,.test{
  width: 100%;
  font-size: 10px;
}
pre{
  font-size: 17px;
  display: flex;
  white-space: normal;
}
.barcode-label{
  height: 45px;
}
.img-test{
  height: 100%;
}
.img-femur{
  position: absolute;
  top: -40%;
}
.img-tibia{
  position: absolute;
  top: -50%;
}
.img-sag-tibia{
  position: absolute;
  top: -100%;
}
.img-screenshot{
  //max-width: 800px;
  height: 350px;
}
.HA-screenshot{
  padding: 0 24px;
  width: 100%;
}
.modal-test{
  width: 80%;
  height: 80%;
}
.dz-preview,.dz-started {
  display: none;
}
.select-controal{
  width: 100%;
  height: 100%;
}
.css-10nd86i{
  padding: 0;
}
.noresize {
  resize: vertical;
}
.modal-screenshot{
  width: 1130px;
}
.modal-control{
  width: 85%;
  height: 800px;
}
.slider_info{
  height: 100%;
}
.slider_cor_tibia{
  height: 100%;
}
.slick-list,.slick-track{
  height: 90%;
}
.messages-container{
  background:#f4f7f9;
  max-height: 180px;
  height: 50vh;
  overflow-y: scroll;
  padding-top: 10px;
}
.rcw-send{
  background: lemonchiffon;
}
.rcw-new-message{
  background: lightgrey;

}

.align-test{
  text-align: center;
  width: 1315px;
  height: 700px;
  overflow: hidden;

}
.final-qc-img{
  width: 100%;
  height: 400px;

}
.drop_zone{
  height: 100%;
  padding-top: 8%;
  font-size: 50px;
}

input[type="file"],.no_message {
  display: none;
}
.case_detail_layout_down{
  margin: 20px 30px;
  font-size: 16px;
}
.qc-check{
  margin: 10px 0;
}
.btn{
  border-radius: 30px;
}
.qc-barcode-btn{
  height: 82px;
}
.block-font{
  color: blue;
}
.mfg-btn{
  height: 60px;
}
.case-status{
  padding: 10px 50px;
}
.flag-info{
  font-size: 20px;
}
.tr-accept{
  background-color: lightgreen;
}
.tr-flag{
  background-color: lightpink;
}
.padding_test{
  padding-top: 50px;
}
.height_test{
  height: 500px;
}
.dz-message{
  font-size: 50px;
}

@media print {
  .print-button {
    display: none;
  }
}
.container-fluid{
  padding-bottom: 15px;
}

.accepted{
  display: none;
}
